// config.js or ConfigService.js

import { IConfig } from "../interfaces/configInterface";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import TranslateIcon from '@mui/icons-material/Translate';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import EditLocationAltOutlinedIcon from '@mui/icons-material/EditLocationAltOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import FeedIcon from '@mui/icons-material/Feed';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import { ModuleNames, RoutesConstatnts } from "../utils/enums";

const config: IConfig = {
  cmsConfig: {
    apiEndpoint: {
      loginApiURL: "/api/v1/paneluser/login",
      logoutApiUrl: "/api/v1/paneluser/logout",
      themeList: "/api/v1/theme/get",
      createTheme: "/api/v1/theme/create",
      deleteTheme: "/api/v1/theme/delete",
      editTheme: "/api/v1/theme/get",
      updateTheme: "/api/v1/theme/update",
      brandingList: "/api/v1/branding/get",
      createBrand: "/api/v1/branding/create",
      deleteBrand: "/api/v1/branding/delete",
      editBrand: "/api/v1/branding/get",
      updateBrand: "/api/v1/branding/update",
      configList: "/api/v1/config/get",
      createConfig: "/api/v1/config/create",
      languageList: "/api/v1/language/get",
      deleteLanguage: "/api/v1/language/delete",
      editLanguage: "/api/v1/language/get",
      updateLanguage: "/api/v1/language/update",
      createLanguage: "/api/v1/language/create",
      groupList: "/api/v1/group/get",
      deleteConfig: "/api/v1/config/delete",
      deleteGroup: "/api/v1/group/delete",
      createGroup: "/api/v1/group/create",
      editGroup: "/api/v1/group/get",
      updateGroup: "/api/v1/group/update",
      navigationList: "/api/v1/navigation/get",
      deleteNavigation: '/api/v1/navigation/delete',
      editNavigation: '/api/v1/navigation/get',
      updateNavigation: '/api/v1/navigation/update',
      createNavigation: '/api/v1/navigation/create',
      categoryList: "/api/v1/category/get",
      createCategory: "/api/v1/category/create",
      editCategory: "/api/v1/category/get",
      updatCategory: "/api/v1/category/update",
      deleteCategory: "/api/v1/category/delete",
      categoryTypeList: "/api/v1/categoryType/get",
      deleteCategoryType: '/api/v1/categoryType/delete',
      editCategoryType: '/api/v1/categoryType/get',
      updateCategoryType: '/api/v1/categoryType/update',
      createCategoryType: '/api/v1/categoryType/create',
      blogList: '/api/v1/blog/get',
      deleteBlog: '/api/v1/blog/delete',
      editBlog: '/api/v1/blog/get',
      updateBlog: '/api/v1/blog/update',
      createBlog: '/api/v1/blog/create',
      categoryDetailsList: "/api/v1/categoryDetails/get",
      createCategoryDetails: "/api/v1/categoryDetails/create",
      deleteCategoryDetails: "/api/v1/categoryDetails/delete",
      editCategoryDetails: "/api/v1/categoryDetails/get",
      updateCategoryDetails: "/api/v1/categoryDetails/update",
      topicList: "/api/v1/topic/get",
      createTopic: "/api/v1/topic/create",
      deleteTopic: "/api/v1/topic/delete",
      editTopic: "/api/v1/topic/get",
      updateTopic: "/api/v1/topic/update",
      topicFieldsList: "/api/v1/topicFields/get",
      createTopicFields: "/api/v1/topicFields/create",
      deleteTopicField: "/api/v1/topicFields/delete",
      editTopicFields: "/api/v1/topicFields/get",
      updateTopicField: "/api/v1/topicFields/update",
      editConfig: "/api/v1/config/get",
      updateConfig: "/api/v1/config/update",
      metaTagsList: "/api/v1/metaTags/get",
      createMetaTags: "/api/v1/metaTags/create",
      editMetaTags: "/api/v1/metaTags/get",
      updateMetaTags: "/api/v1/metaTags/update",
      deleteMetaTags: "/api/v1/metaTags/delete",
      gameList: "api/v1/games/get",
      stats: "/api/v1/auth/stats",
      profileMe: "/api/v1/paneluser/profile",
      getAllFixtures: "/api/v1/match/get",
      updateFixtures: "/api/v1/match/update",
      getNewsList: "/api/v1/news/get-news",
      addNews: "/api/v1/news/create-dash-news",
      deleteNews: "/api/v1/news/delete-news",
      getGamesCategoriesList: "api/v1/gameCategories/get",
      editGameCategory: "api/v1/gameCategories/get",
      deleteGameCategory: "api/v1/gameCategories/delete",
      updateGameCategory: "api/v1/gameCategories/update",
      createGameCategory: "api/v1/gameCategories/create",
      getAssignedGames: "api/v1/casinoLobby/get-assigned-games",
      assignGames: "api/v1/casinoLobby/assign-games"
    },
    sidebarNav: [
      {
        link: RoutesConstatnts.routeDashboard,
        section: "dashboard",
        icon: DashboardOutlinedIcon,
        text: ModuleNames.DASHBOARD
      },
      {
        link: RoutesConstatnts.routeTheme,
        section: "theme",
        icon: ColorLensOutlinedIcon,
        text: ModuleNames.THEME
      },
      {
        link: RoutesConstatnts.routeBranding,
        section: "branding",
        icon: ApprovalOutlinedIcon,
        text: ModuleNames.BRANDING
      },
      {
        link: RoutesConstatnts.routeConfig,
        section: "config",
        icon: BorderColorIcon,
        text: ModuleNames.CONFIG
      },
      {
        link: RoutesConstatnts.routeLanguage,
        section: "language",
        icon: TranslateIcon,
        text: ModuleNames.LANGUAGE
      },
      {
        link: RoutesConstatnts.routeGroup,
        section: "group",
        icon: WorkspacesIcon,
        text: ModuleNames.GROUP
      },
      {
        link: RoutesConstatnts.routeNavigation,
        section: "navigation",
        icon: SwitchLeftIcon,
        text: ModuleNames.NAVIGATION
      },
      {
        link: RoutesConstatnts.routeCategory,
        section: "category",
        icon: AssignmentOutlinedIcon,
        text: ModuleNames.CATEGORY
      },
      {
        link: RoutesConstatnts.routeCategoryType,
        section: "categorytype",
        icon: FormatListBulletedIcon,
        text: ModuleNames.CATEGORY_TYPE
      },
      {
        link: RoutesConstatnts.routeCategoryDetails,
        section: "categorydetails",
        icon: EditLocationAltOutlinedIcon,
        text: ModuleNames.CATEGORY_DETAILS
      },
      {
        link: RoutesConstatnts.routeBlog,
        section: "blog",
        icon: RssFeedIcon,
        text: ModuleNames.BLOG
      },
      {
        link: RoutesConstatnts.routeTopic,
        section: "topic",
        icon: TopicOutlinedIcon,
        text: ModuleNames.TOPIC
      },
      {
        link: RoutesConstatnts.routeTopicFields,
        section: "topicfields",
        icon: FeedOutlinedIcon,
        text: ModuleNames.TOPIC_FIELDS
      },
      {
        link: RoutesConstatnts.routeMetaTags,
        section: "metatags",
        icon: LanguageIcon,
        text: ModuleNames.META_TAGS
      },
      {
        link: RoutesConstatnts.routeFixtures,
        section: "fixtures",
        icon: SportsVolleyballIcon,
        text: ModuleNames.FIXTURES
      },
      {
        link: RoutesConstatnts.routeNews,
        section: "news",
        icon: FeedIcon,
        text: ModuleNames.NEWS
      },
      {
        link: RoutesConstatnts.routeCasinoFilters,
        section: "casinoFilters",
        icon: FeedIcon,
        text: ModuleNames.CASINO_FILTERS
      },
      {
        link: RoutesConstatnts.routeCasinoLobby,
        section: "casinoLobby",
        icon: FeedIcon,
        text: ModuleNames.CASINO_LOBBY
      },
    ],
    routesConstatnts: {
      routeTheme: RoutesConstatnts.routeTheme,
      routeBranding: RoutesConstatnts.routeBranding,
      routeConfig: RoutesConstatnts.routeConfig,
      routeLanguage: RoutesConstatnts.routeLanguage,
      routeGroup: RoutesConstatnts.routeGroup,
      routeNavigation: RoutesConstatnts.routeNavigation,
      routeCategory: RoutesConstatnts.routeCategory,
      routeCategoryType: RoutesConstatnts.routeCategoryType,
      routeBlog: RoutesConstatnts.routeBlog,
      routeCategoryDetails: RoutesConstatnts.routeCategoryDetails,
      routeTopic: RoutesConstatnts.routeTopic,
      routeTopicFields: RoutesConstatnts.routeTopicFields,
      routeMetaTags: RoutesConstatnts.routeMetaTags,
      routeFixtures: RoutesConstatnts.routeFixtures,
      routeNews: RoutesConstatnts.routeNews,
      routeCasinoFilters: RoutesConstatnts.routeCasinoFilters,
      routeCasinoLobby: RoutesConstatnts.routeCasinoLobby
    },
    pageTitles: [
      { path: RoutesConstatnts.routeDashboard, title: ModuleNames.DASHBOARD },
      { path: RoutesConstatnts.routeTheme, title: ModuleNames.THEME },
      { path: RoutesConstatnts.routeBranding, title: ModuleNames.BRANDING },
      { path: RoutesConstatnts.routeConfig, title: ModuleNames.CONFIG },
      { path: RoutesConstatnts.routeLanguage, title: ModuleNames.LANGUAGE },
      { path: RoutesConstatnts.routeGroup, title: ModuleNames.GROUP },
      { path: RoutesConstatnts.routeNavigation, title: ModuleNames.NAVIGATION },
      { path: RoutesConstatnts.routeCategory, title: ModuleNames.CATEGORY },
      { path: RoutesConstatnts.routeCategoryType, title: ModuleNames.CATEGORY_TYPE },
      { path: RoutesConstatnts.routeBlog, title: ModuleNames.BLOG },
      { path: RoutesConstatnts.routeCategoryDetails, title: ModuleNames.CATEGORY_DETAILS },
      { path: RoutesConstatnts.routeTopic, title: ModuleNames.TOPIC },
      { path: RoutesConstatnts.routeTopicFields, title: ModuleNames.TOPIC_FIELDS },
      { path: RoutesConstatnts.routeMetaTags, title: ModuleNames.META_TAGS },
      { path: RoutesConstatnts.routeFixtures, title: ModuleNames.FIXTURES },
      { path: RoutesConstatnts.routeNews, title: ModuleNames.NEWS },
      { path: RoutesConstatnts.routeCasinoFilters, title: ModuleNames.CASINO_FILTERS },
      { path: RoutesConstatnts.routeCasinoLobby, title: ModuleNames.CASINO_LOBBY },
    ]
  },
};
export default config;