import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import Stack from "@mui/material/Stack/Stack";
import { ICommonReturnType, IDeleteThemeProps } from "../../../interfaces/interfaceCommon";
import deleteService from "../../../services/deleteService";

export const DeleteModal: React.FC<IDeleteThemeProps> = ({ name, closeModal, modalId, refetch, deleteUrl }) => {

    const handleDelete = async () => {
        try {
            const response: ICommonReturnType = await deleteService(deleteUrl);
            toast.success(response.message);
            closeModal(modalId);
            refetch();
        } catch (error) {
            toast.error(`Failed to delete ${name}`);
        }
    }

    return (
        <div>
            <Stack marginBottom={2} lineHeight={2}>{`Are you sure you want to delete ${name}?`}</Stack>
            <div className={`button_Wrapper`}>
                <Button className="customBtn" type="reset" onClick={() => closeModal(modalId)} size="small" variant="outlined">
                    {"No"}
                </Button>
                <Button className="customBtn" type="submit" size="small" variant="outlined" onClick={handleDelete}>
                    {"Yes"}</Button>
            </div>
        </div>
    )
}