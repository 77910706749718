import React, { useContext, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import useTheme from '@mui/material/styles/useTheme';
import ThemeContext from '../../../store/themeContext';

interface TinyMCEEditorProps {
    value: string;
    handleEditorChange: (content: string) => void;
}

const TinyMCEEditor: React.FC<TinyMCEEditorProps> = ({ value, handleEditorChange }) => {
    const editorRef = useRef(null);
    const theme = useTheme();
    const themeCtx = useContext(ThemeContext);
    let currentTheme = themeCtx.theme;

    const editorStyles = {
        backgroundColor: theme.palette.background.paper, // Light or dark background
        color: theme.palette.text.primary, // Text color
        border: `1px solid ${theme.palette.divider}`, // Optional: border color
    };

    return (
        <div>
            {/* <Editor
                init={{
                    base_url: '/tinymce',
                    suffix: '.min',
                    content_css: '/tinymce/skins/ui/oxide/skin.min.css',
                    relative_urls: false,
                    // plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown',

                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    tinycomments_mode: 'embedded',
                    tinycomments_author: 'Author name',
                    mergetags_list: [
                        { value: 'First.Name', title: 'First Name' },
                        { value: 'Email', title: 'Email' },
                    ],
                    content_style: `
                    body {
                    background-color: ${editorStyles.backgroundColor} !important;
                    color: ${editorStyles.color} !important;
                    }
                `,
                    skin: currentTheme === "dark" ? "oxide-dark" : "oxide"
                }}
                value={value}
                onEditorChange={handleEditorChange}
            /> */}
            <Editor
                tinymceScriptSrc='/tinymce/tinymce.min.js'

                onInit={(_evt, editor: any) => {
                    editorRef.current = editor;
                    editor.setContent(value);
                }}
                licenseKey='gpl'
                initialValue=''
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount', 'table', 'codesample', 'emoticons',
                    ],
                    toolbar: 'undo redo | blocks fontfamily fontsize | ' +
                        'bold italic forecolor underline strikethrough | alignleft aligncenter ' + '| addcomment showcomments |' +
                        'alignright alignjustify | bullist numlist outdent indent | ' + `| link image media table mergetags` + 'emoticons charmap | removeformat' +
                        'removeformat | help',
                    skin: currentTheme === "dark" ? "oxide-dark" : "oxide",
                    content_style: `
                    body {
                    background-color: ${editorStyles.backgroundColor} !important;
                    color: ${editorStyles.color} !important;
                    }
                `
                }}
                value={value}
                onEditorChange={handleEditorChange}
            />
        </div>
    );
};

export default TinyMCEEditor;
