import ReactDOM from "react-dom/client";
import App from "./App";
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { SidebarContextProvider } from "./store/sidebarContext";
import { ThemeContextProvider } from "./store/themeContext";
import { LoginContextProvider } from "./store/loginContext";
import { ModalProvider } from "./store/modalContext";
import { GamesProvider } from "./store/gamesContext";
import { TimezoneProvider } from "./store/timezoneContext";
import { ModulePrivilageProvider } from "./store/modulePrivilageContext";
import { DeviceProvider } from "./store/deviceContext";
// Create a client
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root')!); // Create a root
root.render(
  <QueryClientProvider client={queryClient}>
    <DeviceProvider>
      <LoginContextProvider>
        <GamesProvider>
          <ModulePrivilageProvider>
            <TimezoneProvider>
              <ThemeContextProvider>
                <SidebarContextProvider>
                  <ModalProvider>
                    <Toaster />
                    <App />
                  </ModalProvider>
                </SidebarContextProvider>
              </ThemeContextProvider>
            </TimezoneProvider>
          </ModulePrivilageProvider>
        </GamesProvider>
      </LoginContextProvider>
    </DeviceProvider>
  </QueryClientProvider >,
);
