import axios from "axios";
import { removeLocalStorageData } from "../utils/utils";
import toast from "react-hot-toast";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_LINK,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

axiosInstance.interceptors.request.use(
  async (config: any) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    };
    if (config.data instanceof FormData) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data',
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      toast.error('Session expired please login again');
      localStorage.setItem('isLogin', JSON.stringify(false));
      removeLocalStorageData();//Remove local storgae data and redirect to login page
      setTimeout(() => {
        window.location.href = "/login";
      }, 1500); 
    }
  }
);
export default axiosInstance;